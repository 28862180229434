import React, { useState, useEffect } from "react";
import "./SideMenu.scss";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  Popper,
  MenuItem,
  MenuList,
  Paper,
  Fade,
  ClickAwayListener,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { isUserHasPermission, isSuperAdmin } from "../../utils/Permissions";
import { setClickedPageIndex } from "../../store/actions/clickedPage";
import { setSideMenu } from "../../store/actions/SideMenu";
import { injectIntl } from "react-intl";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import logo from "../../assets/images/0-01.png";
import {
  DashboardIcon,
  RequestIcon,
  ReportIcon,
  ManpowerRequestIcon,
  TalentPoolIcon,
  InterviewIcon,
  JobOpeningIcon,
  SettingIcon,
  OrganizationalStructureIcon,
} from "./InjectedImages";

const SideMenu = ({ intl }) => {
  const lang = useSelector((state) => state.locale.lang);
  const sideMenu = useSelector((state) => state.sideMenu);
  const [open, setOpen] = useState(false);
  const [poperType, setPoperType] = useState("manpower");
  const [openJobOpening, setJobOpening] = useState(false);
  const [openApprovals, setOpenApprovals] = useState(false);
  const [openOrganization, setOpenOrganization] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [openMenu, setOpenMenu] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { logo_url } = useSelector((state) => state.company);
  const locale = intl.locale;

  const company_logo =
    localStorage.getItem("company_image_url") === null
      ? logo_url
      : localStorage.getItem("company_image_url");
  const activePageIndex = useSelector((state) => state.activePageIndex.index);
  const dispatch = useDispatch();
  const { messages } = intl;

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopper(false);
    setOpenMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      openMenu(false);
    }
  }

  const handleClickPopper = (newPlacement, type) => (event) => {
    setPoperType(type);
    if (sideMenu.sideMenuWidth === "70px") {
      setAnchorEl(event.currentTarget);
      setOpenPopper((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    } else {
      type === "manpower" && setOpen(!open);
      type === "approvals" && setOpenApprovals(!openApprovals);
      type === "organization" && setOpenOrganization(!openOrganization);
      if (type === "jobOpening") setJobOpening(!openJobOpening);
    }
  };

  useEffect(() => {
    locale === "ar"
      ? sideMenu.sideMenuWidth === "70px"
        ? dispatch(
            setSideMenu({
              sideMenuContent: "none",
              mainMrginLeft: window.location.href.includes("interview")
                ? "0"
                : "40px",
              mainMarginRight: window.location.href.includes("interview")
                ? "70px"
                : "120px",
              navbarMarginLeft: "0px",
              navbarMarginRight: "70px",
              mainMarginTop: window.location.href.includes("interview")
                ? 0
                : "30px",
              backgroundColor: window.location.href.includes("interview")
                ? "rgba(246, 246, 246, 1)"
                : "#fff",
              curvedNavbLeft: "auto",
              curvedNavbRight: "3.2em",
            })
          )
        : dispatch(
            setSideMenu({
              sideMenuContent: "block",
              mainMrginLeft: window.location.href.includes("interview")
                ? "0"
                : "40px",
              mainMarginRight: window.location.href.includes("interview")
                ? window.innerWidth < 900
                  ? "0"
                  : "250px"
                : window.innerWidth < 900
                ? "40px"
                : "290px",
              navbarMarginLeft: "0px",
              navbarMarginRight: "250px",
              mainMarginTop: window.location.href.includes("interview")
                ? 0
                : "30px",
              backgroundColor: window.location.href.includes("interview")
                ? "rgba(246, 246, 246, 1)"
                : "#fff",
              curvedNavbLeft: "auto",
              curvedNavbRight: "16em",
            })
          )
      : sideMenu.sideMenuWidth === "70px"
      ? dispatch(
          setSideMenu({
            sideMenuContent: "none",
            mainMrginLeft: window.location.href.includes("interview")
              ? "70px"
              : "120px",
            mainMarginRight: window.location.href.includes("interview")
              ? "0"
              : "40px",
            navbarMarginLeft: "70px",
            mainMarginTop: window.location.href.includes("interview")
              ? 0
              : "30px",
            backgroundColor: window.location.href.includes("interview")
              ? "rgba(246, 246, 246, 1)"
              : "#fff",
            navbarMarginRight: "0px",
            curvedNavbLeft: "3.2em",
            curvedNavbRight: "auto",
          })
        )
      : dispatch(
          setSideMenu({
            sideMenuContent: "block",
            mainMarginTop: window.location.href.includes("interview")
              ? 0
              : "30px",
            backgroundColor: window.location.href.includes("interview")
              ? "rgba(246, 246, 246, 1)"
              : "#fff",
            mainMrginLeft: window.location.href.includes("interview")
              ? window.innerWidth < 900
                ? "0"
                : "250px"
              : window.innerWidth < 900
              ? "40px"
              : "290px",
            mainMarginRight: window.location.href.includes("interview")
              ? "0"
              : "40px",
            navbarMarginLeft: "250px",
            navbarMarginRight: "0px",
            curvedNavbLeft: "16em",
            curvedNavbRight: "auto",
          })
        );
  }, [locale, sideMenu.sideMenuWidth, window.location.href, dispatch]);

  const closeNav = () => {
    setOpen(false);
    setOpenApprovals(false);
    setJobOpening(false);
    lang === "en"
      ? dispatch(
          setSideMenu({
            closeBtn: "none",
            openBtn: "block",
            companyLogoSideMenuHeight: "50px",
            companyLogoSideMenuWidth: "50px",
            sideMenuContent: "none",
            sideMenuContentPaddingTop: "16px",
            sideMenuContentPaddingBottom: "16px",
            sideMenuWidth: "70px",
            mainMrginLeft: "120px",
            mainMarginRight: "40px",
            navbarMarginLeft: "70px",
            navbarMarginRight: "0px",
            curvedNavbLeft: "3.2em",
            curvedNavbRight: "auto",
            showPopover: "block",
          })
        )
      : dispatch(
          setSideMenu({
            closeBtn: "none",
            openBtn: "block",
            companyLogoSideMenuHeight: "50px",
            companyLogoSideMenuWidth: "50px",
            sideMenuContent: "none",
            sideMenuContentPaddingTop: "16px",
            sideMenuContentPaddingBottom: "16px",
            sideMenuWidth: "70px",
            mainMrginLeft: "40px",
            mainMarginRight: "120px",
            navbarMarginLeft: "0px",
            navbarMarginRight: "70px",
            curvedNavbLeft: "auto",
            curvedNavbRight: "3.2em",
            showPopover: "block",
          })
        );
  };

  const openNav = () => {
    lang === "en"
      ? dispatch(
          setSideMenu({
            closeBtn: "block",
            openBtn: "none",
            companyLogoSideMenuHeight: "100px",
            companyLogoSideMenuWidth: "100px",
            sideMenuContent: "block",
            sideMenuContentPaddingTop: "8px",
            sideMenuContentPaddingBottom: "8px",
            sideMenuWidth: "250px",
            mainMrginLeft: "290px",
            mainMarginRight: "40px",
            navbarMarginLeft: "250px",
            navbarMarginRight: "0px",
            curvedNavbLeft: "16em",
            curvedNavbRight: "auto",
            showPopover: "none",
          })
        )
      : dispatch(
          setSideMenu({
            closeBtn: "block",
            openBtn: "none",
            companyLogoSideMenuHeight: "100px",
            companyLogoSideMenuWidth: "100px",
            sideMenuContent: "block",
            sideMenuContentPaddingTop: "8px",
            sideMenuContentPaddingBottom: "8px",
            sideMenuWidth: "250px",
            mainMrginLeft: "40px",
            mainMarginRight: "290px",
            navbarMarginLeft: "0px",
            navbarMarginRight: "250px",
            curvedNavbLeft: "auto",
            curvedNavbRight: "16em",
            showPopover: "none",
          })
        );
  };

  const handleListItemClick = (event, index) => {
    dispatch(setClickedPageIndex({ index }));
  };
  const setStepperToInitialState = () => {
    localStorage.setItem("createStepperIndex", 0);
    localStorage.removeItem("jobOpeningId");
    localStorage.removeItem("editInprogressRequest");
    localStorage.removeItem("editMode");
    localStorage.setItem("editStep", 0);
    window.location.reload();
  };

  const renderListItem = (
    link,
    message,
    title,
    icon,
    opacity,
    customLogoWidth
  ) => {
    return (
      <>
        <Link to={link}>
          <Tooltip title={message} placement="top-end">
            <ListItem
              className="listItem"
              button
              style={{
                paddingTop: sideMenu.sideMenuContentPaddingTop,
                paddingBottom: sideMenu.sideMenuContentPaddingBottom,
              }}
              selected={activePageIndex === link}
              onClick={(event) => handleListItemClick(event, link)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                className="medium_font"
                primary={message}
                style={{
                  display: sideMenu.sideMenuContent,
                  transition: "0.5s",
                }}
              />
            </ListItem>
          </Tooltip>
        </Link>
      </>
    );
  };

  const renderMenuItem = (link, message) => {
    return (
      <Link to={link}>
        <MenuItem
          onClick={handleCloseMenu}
          className={
            lang === "ar"
              ? "text-right medium_font text-dark"
              : "text-left medium_font text-dark"
          }
          dir={lang === "ar" ? "rtl" : "ltr"}
        >
          {message}
        </MenuItem>
      </Link>
    );
  };

  return (
    <React.Fragment>
      <Popper
        style={{ display: sideMenu.showPopover }}
        dir={lang === "ar" ? "rtl" : "ltr"}
        className={lang === "ar" ? "text-right" : "text-left"}
        open={openPopper}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                {poperType === "manpower" ? (
                  <MenuList
                    autoFocusItem={openMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <div onClick={setStepperToInitialState}>
                      {renderMenuItem(
                        "/manpowerRequests",
                        messages.sideMenu.createNewRequest
                      )}
                    </div>
                    {renderMenuItem(
                      "/viewAllRequests",
                      messages.sideMenu.viewAllrequests
                    )}
                  </MenuList>
                ) : poperType === "organization" ? (
                  <MenuList
                    autoFocusItem={openMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {renderMenuItem(
                      "/OrganizationalStructure",
                      messages.sideMenu.OrganizationalStructure
                    )}
                    {renderMenuItem(
                      "/positionChart",
                      messages.sideMenu.positionChart
                    )}
                  </MenuList>
                ) : (
                  <MenuList
                    autoFocusItem={openMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {renderMenuItem(
                      "/Requests",
                      messages.sideMenu.agentsRequests
                    )}
                    {renderMenuItem(
                      "/candidateApprovals",
                      messages.sideMenu.candidatesRequests
                    )}
                  </MenuList>
                )}
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <div
        className="sidenav"
        id="mySidenav"
        style={{ width: sideMenu.sideMenuWidth }}
      >
        <div
          className="companyLogoSideMenu"
          style={{
            backgroundImage: `url(${
              company_logo?.includes("https")
                ? company_logo
                : `data:image/png;base64,${company_logo}`
            })`,
            height: sideMenu.companyLogoSideMenuHeight,
            width: sideMenu.companyLogoSideMenuWidth,
          }}
        ></div>
        <div
          className="curvedNav"
          style={{
            left: sideMenu.curvedNavbLeft,
            right: sideMenu.curvedNavbRight,
          }}
        >
          <button
            className="btn closebtn"
            onClick={closeNav}
            style={{ display: sideMenu.closeBtn }}
          >
            <ArrowBackIosIcon fontSize="small" />
          </button>
          <button
            className="btn openBtn"
            onClick={openNav}
            style={{ display: sideMenu.openBtn }}
          >
            <ArrowForwardIosIcon fontSize="small" />
          </button>
        </div>
        <List
          component="nav"
          className="sideMenuItemList"
          aria-label="sideMenu"
        >
          {renderListItem(
            "/",
            messages.sideMenu.Dashboard,
            "Dashboard",
            <DashboardIcon />,
            1
          )}
          <>
            <Tooltip
              title={messages.sideMenu.ManpowerRequests}
              placement="top-end"
            >
              <ListItem
                button
                className="listItem"
                onClick={handleClickPopper("right-start", "manpower")}
                style={{
                  paddingTop: sideMenu.sideMenuContentPaddingTop,
                  paddingBottom: sideMenu.sideMenuContentPaddingBottom,
                  transition: "0.5s",
                }}
              >
                <ListItemIcon>
                  {<ManpowerRequestIcon key="manpower" />}
                </ListItemIcon>
                <ListItemText
                  className="medium_font"
                  primary={messages.sideMenu.ManpowerRequests}
                  style={{
                    display: sideMenu.sideMenuContent,
                    transition: "0.5s",
                  }}
                />
                {open ? (
                  <ExpandLess
                    style={{
                      display: sideMenu.sideMenuContent,
                      transition: "0.5s",
                    }}
                  />
                ) : (
                  <ExpandMore
                    style={{
                      display: sideMenu.sideMenuContent,
                      transition: "0.5s",
                    }}
                  />
                )}
              </ListItem>
            </Tooltip>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              style={{
                display: sideMenu.sideMenuContent,
                transition: "0.5s",
              }}
            >
              {(isUserHasPermission("JOB_DETAILS") || isSuperAdmin()) && (
                <div onClick={setStepperToInitialState}>
                  {renderListItem(
                    "/manpowerRequests",
                    messages.sideMenu.createNewRequest,
                    "Manpower requests",
                    "",
                    0
                  )}
                </div>
              )}
              {(isSuperAdmin() ||
                isUserHasPermission("VIEW_PENDING_JOB_REQUESTS") ||
                isUserHasPermission("LIST_JOB_REQUESTS") ||
                isUserHasPermission("VIEW_CLOSED_JOB_REQUESTS")) &&
                renderListItem(
                  "/viewAllRequests",
                  messages.sideMenu.viewAllrequests,
                  "Manpower requests",
                  "",
                  0
                )}
            </Collapse>
            {(isUserHasPermission("APPROVAL") ||
              isUserHasPermission("APPROVE_CANDIDATE_REQUESTS") ||
              isSuperAdmin()) && (
              <Tooltip title={messages.Requests.Requests} placement="top-end">
                <ListItem
                  button
                  className="listItem"
                  onClick={handleClickPopper("right-start", "approvals")}
                  style={{
                    paddingTop: sideMenu.sideMenuContentPaddingTop,
                    paddingBottom: sideMenu.sideMenuContentPaddingBottom,
                    transition: "0.5s",
                  }}
                >
                  <ListItemIcon>
                    <RequestIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="medium_font"
                    primary={messages.Requests.Requests}
                    style={{
                      display: sideMenu.sideMenuContent,
                      transition: "0.5s",
                    }}
                  />
                  {openApprovals ? (
                    <ExpandLess
                      style={{
                        display: sideMenu.sideMenuContent,
                        transition: "0.5s",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      style={{
                        display: sideMenu.sideMenuContent,
                        transition: "0.5s",
                      }}
                    />
                  )}
                </ListItem>
              </Tooltip>
            )}
            <Collapse
              in={openApprovals}
              timeout="auto"
              unmountOnExit
              style={{
                display: sideMenu.sideMenuContent,
                transition: "0.5s",
              }}
            >
              {(isUserHasPermission("APPROVAL") || isSuperAdmin()) &&
                renderListItem(
                  "/Requests",
                  messages.sideMenu.agentsRequests,
                  "Requests",
                  "",
                  0
                )}
              {(isUserHasPermission("APPROVE_CANDIDATE_REQUESTS") ||
                isSuperAdmin()) &&
                renderListItem(
                  "/candidateApprovals",
                  messages.sideMenu.candidatesRequests,
                  "Candidates requests",
                  "",
                  0
                )}
            </Collapse>
            {/* {(isUserHasPermission("LIST_ALL_JOB_OPENINGS") || isSuperAdmin()) &&
              renderListItem(
                "/jobOpenings",
                messages.sideMenu.Jobopening,
                "Job opening",
                <JobOpeningIcon />,
                1
              )} */}

            {/* 88888888888888888888888888888888888888888888888888888888888888888888888888888888888 */}
            {(isUserHasPermission("LIST_ALL_JOB_OPENINGS") ||
              isSuperAdmin()) && (
              <Tooltip title={messages.Requests.Requests} placement="top-end">
                <ListItem
                  button
                  className="listItem"
                  onClick={handleClickPopper("right-start", "jobOpening")}
                  style={{
                    paddingTop: sideMenu.sideMenuContentPaddingTop,
                    paddingBottom: sideMenu.sideMenuContentPaddingBottom,
                    transition: "0.5s",
                  }}
                >
                  <ListItemIcon>
                    <JobOpeningIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="medium_font"
                    primary={messages.sideMenu.Jobopening}
                    style={{
                      display: sideMenu.sideMenuContent,
                      transition: "0.5s",
                    }}
                  />
                  {openJobOpening ? (
                    <ExpandLess
                      style={{
                        display: sideMenu.sideMenuContent,
                        transition: "0.5s",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      style={{
                        display: sideMenu.sideMenuContent,
                        transition: "0.5s",
                      }}
                    />
                  )}
                </ListItem>
              </Tooltip>
            )}
            <Collapse
              in={openJobOpening}
              timeout="auto"
              unmountOnExit
              style={{
                display: sideMenu.sideMenuContent,
                transition: "0.5s",
              }}
            >
              {(isUserHasPermission("LIST_ALL_JOB_OPENINGS") ||
                isSuperAdmin()) &&
                renderListItem(
                  "/jobOpenings",
                  messages.sideMenu.activejobs,
                  "job Openings",
                  "",
                  0
                )}
              {(isUserHasPermission("LIST_ALL_JOB_OPENINGS") ||
                isSuperAdmin()) &&
                renderListItem(
                  "/Archive",
                  messages.sideMenu.Archived,
                  "job Openings Archive",
                  "",
                  0
                )}
            </Collapse>
            {(isUserHasPermission("MY_INTERVIEW_GRID") || isSuperAdmin()) &&
              renderListItem(
                "/myInterviews",
                messages.myInterviews.myInterviews,
                "My interviews",
                <InterviewIcon />,
                1
              )}
            {renderListItem(
              "/hiringMangerApproval",
              messages.sideMenu.candidatesApproval,
              "My interviews",
              <RequestIcon />,
              1
            )}

            {(isUserHasPermission("TALENT_POOL_LIST") || isSuperAdmin()) &&
              renderListItem(
                "/TalentPool",
                messages.sideMenu.TalentPool,
                "Talent Pool",
                <TalentPoolIcon />,
                1
              )}
            {(isUserHasPermission("VIEW_ORGANIZATIONAL_STRUCTURE") ||
              isUserHasPermission("VIEW_JOB_TITLE") ||
              isSuperAdmin()) && (
              <Tooltip
                title={messages.sideMenu.Organization}
                placement="top-end"
              >
                <ListItem
                  button
                  className="listItem"
                  onClick={handleClickPopper("right-start", "organization")}
                  style={{
                    paddingTop: sideMenu.sideMenuContentPaddingTop,
                    paddingBottom: sideMenu.sideMenuContentPaddingBottom,
                    transition: "0.5s",
                  }}
                >
                  <ListItemIcon>
                    <OrganizationalStructureIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="medium_font"
                    primary={messages.sideMenu.Organization}
                    style={{
                      display: sideMenu.sideMenuContent,
                      transition: "0.5s",
                    }}
                  />
                  {openOrganization ? (
                    <ExpandLess
                      style={{
                        display: sideMenu.sideMenuContent,
                        transition: "0.5s",
                      }}
                    />
                  ) : (
                    <ExpandMore
                      style={{
                        display: sideMenu.sideMenuContent,
                        transition: "0.5s",
                      }}
                    />
                  )}
                </ListItem>
              </Tooltip>
            )}
            <Collapse
              in={openOrganization}
              timeout="auto"
              unmountOnExit
              style={{
                display: sideMenu.sideMenuContent,
                transition: "0.5s",
              }}
            >
              {(isUserHasPermission("VIEW_ORGANIZATIONAL_STRUCTURE") ||
                isSuperAdmin()) &&
                renderListItem(
                  "/OrganizationalStructure",
                  messages.sideMenu.OrganizationalStructure,
                  "OrganizationalStructure",
                  "",
                  0
                )}
              {(isUserHasPermission("VIEW_JOB_TITLE") || isSuperAdmin()) &&
                renderListItem(
                  "/positionChart",
                  messages.sideMenu.positionChart,
                  "positionChart",
                  "",
                  0
                )}
            </Collapse>
            {(isUserHasPermission("VIEW_REPORTS") || isSuperAdmin()) &&
              renderListItem(
                "/report",
                messages.sideMenu.report,
                "Report",
                <ReportIcon />,
                1,
                "16px"
              )}
            {(isUserHasPermission("SETUP") || isSuperAdmin()) &&
              renderListItem(
                "/setup",
                messages.sideMenu.Settings,
                "Settings",
                <SettingIcon />,
                1
              )}
          </>
          {/* )} */}
        </List>
        {/* <img
          src={logo}
          style={{
            height: "4.1em",
          }}
          alt="HRCom logo"
          className={
            sideMenu.sideMenuWidth === "70px"
              ? "logoSideNav m-2"
              : "logoSideNav m-3"
          }
        /> */}
      </div>
    </React.Fragment>
  );
};

const SideMenuComponent = injectIntl(SideMenu);

export default SideMenuComponent;
