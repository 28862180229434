export const SCHEDULE_INTERVIEW_REQUEST = "SCHEDULE_INTERVIEW_REQUEST";
export const SCHEDULE_INTERVIEW_RECIEVE = "SCHEDULE_INTERVIEW_RECIEVE";
export const REQUEST_GET_PIPELINE_APPLICATIONS =
  "REQUEST_GET_PIPELINE_APPLICATIONS";
export const RECEIVE_GET_PIPELINE_APPLICATIONS =
  "RECEIVE_GET_PIPELINE_APPLICATIONS";
export const REQUEST_GET_RESCHEDULE_DATA = "REQUEST_GET_RESCHEDULE_DATA";
export const RECEIVE_GET_RESCHEDULE_DATA = "RECEIVE_GET_RESCHEDULE_DATA";
export const REQUEST_RESCHEDULE_INTERVIEW = "REQUEST_RESCHEDULE_INTERVIEW";
export const RECEIVE_RESCHEDULE_INTERVIEW = "RECEIVE_RESCHEDULE_INTERVIEW";
export const REQUEST_ADD_APPLICANTS = "REQUEST_ADD_APPLICANTS";
export const RECEIVE_ADD_APPLICANTS = "RECEIVE_ADD_APPLICANTS";
export const REQUEST_GET_PIPELINE_STAGES = "REQUEST_GET_PIPELINE_STAGES";
export const RECEIVE_GET_PIPELINE_STAGES = "RECEIVE_GET_PIPELINE_STAGES";
export const REQUEST_REJECT_APPLICANTS = "REQUEST_REJECT_APPLICANTS";
export const REQUEST_SEND_JD = "REQUEST_SEND_JD";
export const SAVE_CURRENT_STAGE_TAB_INDEX = "SAVE_CURRENT_STAGE_TAB_INDEX";
export const REQUEST_MOVE_APPLICANT_ANOTHER_STAGE =
  "REQUEST_MOVE_APPLICANT_ANOTHER_STAGE";
export const RECEIVE_MOVE_APPLICANT_ANOTHER_STAGE =
  "RECEIVE_MOVE_APPLICANT_ANOTHER_STAGE";
export const REQUEST_ADD_RATE_TO_APPLICANTS = "REQUEST_ADD_RATE_TO_APPLICANTS";
export const REQUEST_GET_APPLICANT_RATE = "REQUEST_GET_APPLICANT_RATE";
export const RECEIVE_GET_APPLICANT_RATE = "RECEIVE_GET_APPLICANT_RATE";
export const REQUEST_PREOFFER_DATA = "REQUEST_PREOFFER_DATA";
export const RECEIVE_PREOFFER_DATA = "RECEIVE_PREOFFER_DATA";
export const REQUEST_SEND_CUSTOMIZED_EMAIL = "REQUEST_SEND_CUSTOMIZED_EMAIL";
export const REQUEST_SEND_UPDATE_CV_EMAIL = "REQUEST_SEND_UPDATE_CV_EMAIL";
export const SEND_APPLICANT_PREOFFER_REQUEST =
  "SEND_APPLICANT_PREOFFER_REQUEST";
export const ADD_APPLICANT_TO_ANOTHER_JOB_OPENING_REQUEST =
  "ADD_APPLICANT_TO_ANOTHER_JOB_OPENING_REQUEST";
export const DISABLE_ADD_APPLICANTS_REQUEST = "DISABLE_ADD_APPLICANTS_REQUEST";
export const DISABLE_ADD_APPLICANTS_RECEIVE = "DISABLE_ADD_APPLICANTS_RECEIVE";

export const DISABLE_ENGAGEMENT_REQUEST = "DISABLE_ENGAGEMENT_REQUEST";
export const DISABLE_ENGAGEMENT_RECEIVE = "DISABLE_ENGAGEMENT_RECEIVE";
export const REQUEST_ADD_APPLICANT_MORE_INFO =
  "REQUEST_ADD_APPLICANT_MORE_INFO";
export const RECEIVE_ADD_APPLICANT_MORE_INFO =
  "RECEIVE_ADD_APPLICANT_MORE_INFO";

export const REQUEST_CHANGE_STAGE_SEND_EMAIL =
  "REQUEST_CHANGE_STAGE_SEND_EMAIL";

export const GET_SEND_OFFER_TEMPLATE_REQUEST =
  "GET_SEND_OFFER_TEMPLATE_REQUEST";
export const GET_SEND_OFFER_TEMPLATE_RECEIVE =
  "GET_SEND_OFFER_TEMPLATE_RECEIVE";

export const GET_SEND_CONTRACT_TEMPLATE_REQUEST =
  "GET_SEND_CONTRACT_TEMPLATE_REQUEST";
export const GET_SEND_CONTRACT_TEMPLATE_RECEIVE =
  "GET_SEND_CONTRACT_TEMPLATE_RECEIVE";

export const SEND_CONTRACT_REQUEST = "SEND_CONTRACT_REQUEST";
export const SEND_RECONTRACT_REQUEST = "SEND_RECONTRACT_REQUEST";
export const SEND_OFFER_REQUEST = "SEND_OFFER_REQUEST";
export const SEND_OFFER_RECEIVE = "SEND_OFFER_RECEIVE";

export const UPLOAD_AND_VALIDATE_FILE_REQUEST =
  "UPLOAD_AND_VALIDATE_FILE_REQUEST";
export const UPLOAD_AND_VALIDATE_FILE_RECEIVE =
  "UPLOAD_AND_VALIDATE_FILE_RECEIVE";

export const UPDATE_UPLOAD_AND_VALIDATE_FILE_REQUEST =
  "UPDATE_UPLOAD_AND_VALIDATE_FILE_REQUEST";
export const UPDATE_UPLOAD_AND_VALIDATE_FILE_RECEIVE =
  "UPDATE_UPLOAD_AND_VALIDATE_FILE_RECEIVE";
export const SEND_REOFFER_REQUEST = "SEND_REOFFER_REQUEST";
export const SEND_REOFFER_RECEIVE = "SEND_REOFFER_RECEIVE";

export const GET_OFFER_APPROVALS_SETUP_REQUEST =
  "GET_OFFER_APPROVALS_SETUP_REQUEST";
export const GET_OFFER_APPROVALS_SETUP_RECEIVE =
  "GET_OFFER_APPROVALS_SETUP_RECEIVE";
export const GET_CONTRACT_APPROVALS_SETUP_RECEIVE =
  "GET_CONTRACT_APPROVALS_SETUP_RECEIVE";
export const GET_CONTRACT_APPROVALS_SETUP_REQUEST =
  "GET_CONTRACT_APPROVALS_SETUP_REQUEST";
export const GET_OFFER_APPROVALS_STATUS_LIST_REQUEST =
  "GET_OFFER_APPROVALS_STATUS_LIST_REQUEST";
export const GET_OFFER_APPROVALS_STATUS_LIST_RECEIVE =
  "GET_OFFER_APPROVALS_STATUS_LIST_RECEIVE";
export const REQUEST_OFFER_SALARY_DETAILS = "REQUEST_OFFER_SALARY_DETAILS";
export const RECIEVE_OFFER_SALARY_DETAILS = "RECIEVE_OFFER_SALARY_DETAILS";

export const RECIEVE_TOGGLE_FAVORITE_CANDIDATE =
  "RECIEVE_TOGGLE_FAVORITE_CANDIDATE";
export const REQUEST_TOGGLE_FAVORITE_CANDIDATE =
  "REQUEST_TOGGLE_FAVORITE_CANDIDATE";
export const REQUEST_DEMO_ACTION = "REQUEST_DEMO_ACTION";
export const REQUEST_DEMO_Modal = "REQUEST_DEMO_Modal";

export const GET_CONTRACT_APPROVALS_STATUS_LIST_REQUEST =
  "GET_CONTRACT_APPROVALS_STATUS_LIST_REQUEST";
export const GET_CONTRACT_APPROVALS_STATUS_LIST_RECEIVE =
  "GET_CONTRACT_APPROVALS_STATUS_LIST_RECEIVE";

export const GET_CANDIDATE_PERMISSIONS_REQUEST =
  "GET_CANDIDATE_PERMISSIONS_REQUEST";
export const GET_CANDIDATE_PERMISSIONS_RECEIVE =
  "GET_CANDIDATE_PERMISSIONS_RECEIVE";

export const REQUEST_EDIT_CANDIDATE_PERMISSIONS =
  "REQUEST_EDIT_CANDIDATE_PERMISSIONS";
export const RECEIVE_EDIT_CANDIDATE_PERMISSIONS =
  "RECEIVE_EDIT_CANDIDATE_PERMISSIONS";

export const CLEAR_CANDIDATE_PERMISSIONS = "CLEAR_CANDIDATE_PERMISSIONS";

export const GET_JOB_DETAILS_PERMISSIONS_REQUEST =
  "GET_JOB_DETAILS_PERMISSIONS_REQUEST";
export const GET_JOB_DETAILS_PERMISSIONS_RECEIVE =
  "GET_JOB_DETAILS_PERMISSIONS_RECEIVE";

export const REQUEST_EDIT_JOB_DETAILS_PERMISSIONS =
  "REQUEST_EDIT_JOB_DETAILS_PERMISSIONS";
export const RECEIVE_EDIT_JOB_DETAILS_PERMISSIONS =
  "RECEIVE_EDIT_JOB_DETAILS_PERMISSIONS";

export const CLEAR_JOB_DETAILS_PERMISSIONS = "CLEAR_JOB_DETAILS_PERMISSIONS";
export const REQUEST_MEETING_PLATFORMS = "REQUEST_MEETING_PLATFORMS";
export const RECEIVE_MEETING_PLATFORMS = "RECEIVE_MEETING_PLATFORMS";

export const REQUEST_HIRING_MANGER_APPROVAL = "REQUEST_HIRING_MANGER_APPROVAL";
export const RECEIVE_HIRING_MANGER_APPROVAL = "RECEIVE_HIRING_MANGER_APPROVAL";

export const REQUEST_SHORTLISTED_CANDIDATES_APPROVAL = "REQUEST_SHORTLISTED_CANDIDATES_APPROVAL";
export const RECEIVE_SHORTLISTED_CANDIDATES_APPROVAL = "RECEIVE_SHORTLISTED_CANDIDATES_APPROVAL";
