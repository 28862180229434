import { axiosInstance } from "./config";

let handlerEnabled = true;

const getPipelineApplications = async ({ params, job_request_id }) => {
  return await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications`,
    {
      params: {
        ...params,
        page_size: 10,
      },
      handlerEnabled,
    }
  );
};

const getCandidatePermissions = async (id) => {
  return await axiosInstance.get(
    `/admin/job_role/${id}/manage_candidate_profile`,
    {
      handlerEnabled,
    }
  );
};

const editCandidatePermissions = async ({ job_role, values }) => {
  return await axiosInstance.put(
    `/admin/job_role/${job_role}/manage_candidate_profile`,
    values,
    {
      handlerEnabled,
    }
  );
};

const getJobDetailsPermissions = async (id) => {
  return await axiosInstance.get(`/admin/job_role/${id}/manage_job_details`, {
    handlerEnabled,
  });
};

const editJobDetailsPermissions = async ({ job_role, values }) => {
  return await axiosInstance.put(
    `/admin/job_role/${job_role}/manage_job_details`,
    values,
    {
      handlerEnabled,
    }
  );
};
const getPipelineStages = async (job_request_id) =>
  await axiosInstance.get(`/admin/job_requests/${job_request_id}/pipeline`, {
    handlerEnabled,
  });

const getMeetingPlatforms = async (job_request_id) =>
  await axiosInstance.get(`/admin/interviews/meeting_platforms`, {
    handlerEnabled,
  });
const scheduleInterview = async (data) => {
  await axiosInstance.post(
    `/admin/job_requests/${data.job_request_id}/applications/${data.data.candidate_application_id}/interviews`,
    data.data,
    { handlerEnabled }
  );
};
const hiringMangerApproval = async (data) =>
  await axiosInstance.post(
    `/admin/job_request/UpdateCandProfileStatus/${data.candidate_Application_Id}`,
    data.data,
    { handlerEnabled }
  );
const shortlistedCandidatesApprovalRequest = async ({ page_number }) =>
  await axiosInstance.get(`/admin/job_request/applications`, {
    params: {
      page_number,
      page_size: 10,
    },
    handlerEnabled,
  });

const requestGetScheduleData = async ({
  interview_id,
  application_id,
  job_request_id,
}) =>
  await axiosInstance.get(
    `admin/job_requests/${job_request_id}/applications/${application_id}/interviews/${interview_id}`,
    { handlerEnabled }
  );

const reScheduleInterview = async ({
  params: { job_request_id, application_id, interview_id },
  data,
}) =>
  await axiosInstance.put(
    `admin/job_requests/${job_request_id}/applications/${application_id}/interviews/${interview_id}`,
    data,
    { handlerEnabled }
  );

const addApplicants = async (data) =>
  await axiosInstance.post(`/admin/candidates`, data, {
    handlerEnabled,
  });

const rejectApplicants = async ({ job_request_id, data }) => {
  return await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${data.candidate_application_id}/actions/reject`,
    data
  );
};
const sendJDAction = async (data) => {
  return await axiosInstance.post("/candidate/SendJDEmail", data);
};

const sendUploadAndValidateFile = async (data) =>
  await axiosInstance.post(`/candidate/Documents`, data);

const updateUploadAndValidateFile = async (data) =>
  await axiosInstance.put(`/candidate/Documents`, data);

const moveCandidatesToStages = async ({ job_request_id, data }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${data.candidate_application_id}/new_stage`,
    data
  );

const requestPreofferData = async (candidate_id) =>
  await axiosInstance.get(
    `/admin/candidates/${candidate_id}/preoffer/attachment_types`
  );

const sendApplicantPreoffer = async ({
  job_request_id,
  application_id,
  data,
}) =>
  await axiosInstance.post(
    `admin/job_requests/${job_request_id}/applications/${application_id}/actions/preoffer
  `,
    data
  );

const addRateToApplicants = async (data) =>
  await axiosInstance.post(
    `/admin/candidates/${data.candidate_id}/AddEditRate`,
    data
  );

const getApplicatRate = async (candidate_id) =>
  await axiosInstance.get(`/admin/candidates/GetRate/${candidate_id}`, {
    handlerEnabled,
  });

const sendCustomizedEmail = async (data) =>
  await axiosInstance.post(`/admin/candidates/send_customized_email`, data);

const sendUpdateCVEmail = async (data) => {
  return await axiosInstance.post(
    `/admin/candidates/send_update_resume_email`,
    data
  );
};

const addToAnotherJobRequest = async (data) =>
  await axiosInstance.post(
    `/admin/candidates/add_to_job_opening/${data.job_request_id}`,
    data
  );

const disableAddApplicants = async () =>
  await axiosInstance.get(`/admin/candidates/disable_add_applicant`);
const disableEngagement = async () =>
  await axiosInstance.get(`/admin/subscriptions/disable_engagement`);

const AddMoreApplicantInfo = async ({ data }) =>
  await axiosInstance.post(`/admin/moreInformationRequest/add`, data);

const SendEmailChangeStage = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_email`,
    data
  );

const getSendOfferTemplate = async ({
  application_id,
  job_request_id,
  basic_salary,
  show_allowance_as_percentage,
}) =>
  await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_offer`,
    {
      params: {
        basic_salary: basic_salary,
        show_allowance_as_percentage: show_allowance_as_percentage,
      },
      handlerEnabled,
    }
  );
const getOfferSalaryDetails = async ({ job_request_id, application_id }) =>
  await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/offer_salary_details`
  );
const sendContract = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_contract`,
    data
  );
const sendReContract = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_reContract`,
    data
  );
export const downloadContractPDFFileRequest = async ({ id }) => {
  return await axiosInstance.get(
    `/admin/contracts/downloadContract_AttachFile/${id}`,
    {
      responseType: "arraybuffer",
      disableLoader: true,
    }
  );
};

const getSendContractTemplate = async ({ application_id, job_request_id }) =>
  await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_contract`
  );
const sendOffer = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_offer`,
    data
  );

const sendReoffer = async ({ data, application_id, job_request_id }) =>
  await axiosInstance.post(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/actions/send_reoffer`,
    data
  );

const getOfferApprovalsSetup = async (payload) =>
  await axiosInstance.get(`admin/offers/approval_setup`, {
    params: { ...payload },
  });
const getContractApprovalsSetup = async (payload) =>
  await axiosInstance.get(`admin/contracts/approval_setup`, {
    params: { ...payload },
  });

const getOfferApprovalStatusList = async (offer_id) =>
  await axiosInstance.get(`admin/offers/${offer_id}/approval_status`);

const requestFavoriteCandidate = async (candidate_id) =>
  await axiosInstance.post(`/admin/candidates/toggle_favourite`, {
    candidate_id,
  });

const requestDemo = async (payload) =>
  axiosInstance.post(`/company/request_demo`, payload);

const getContractApprovalStatusList = async (contract_id) =>
  await axiosInstance.get(`admin/contracts/${contract_id}/approval_status`);

export default {
  scheduleInterview,
  getPipelineApplications,
  requestGetScheduleData,
  reScheduleInterview,
  addApplicants,
  getPipelineStages,
  rejectApplicants,
  moveCandidatesToStages,
  requestPreofferData,
  sendApplicantPreoffer,
  addRateToApplicants,
  getApplicatRate,
  sendJDAction,
  sendCustomizedEmail,
  sendUpdateCVEmail,
  addToAnotherJobRequest,
  disableAddApplicants,
  disableEngagement,
  AddMoreApplicantInfo,
  SendEmailChangeStage,
  getSendOfferTemplate,
  getSendContractTemplate,
  sendUploadAndValidateFile,
  sendContract,
  sendOffer,
  sendReoffer,
  updateUploadAndValidateFile,
  getOfferApprovalsSetup,
  getContractApprovalsSetup,
  getOfferApprovalStatusList,
  requestFavoriteCandidate,
  requestDemo,
  sendReContract,
  getContractApprovalStatusList,
  getOfferSalaryDetails,
  getCandidatePermissions,
  editCandidatePermissions,
  getJobDetailsPermissions,
  editJobDetailsPermissions,
  getMeetingPlatforms,
  hiringMangerApproval,
  shortlistedCandidatesApprovalRequest,
};
