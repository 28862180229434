import * as types from "../types/manageCandidates";
import { UPLOAD_AND_VALIDATE_FILE_RECEIVE } from "./../types/manageCandidates";

const INITIAL_STATE = {
  list: {},
  rescheduleData: {},
  successAddedApplicant: false,
  candidatePermissions: [],
  jobDetailsPermissions: [],
  pipelineStages: [],
  scheduleSetupData: {},
  currentStageIndex: 0,
  preofferData: [],
  rate: null,
  disableAddApplicants: null,
  disableEngagement: null,
  successRequestMoreInfo: "",
  offerTemplate: {},
  contractTemplate: {},
  isOfferHasApprovals: false,
  isContractHasApprovals: false,
  approvalStatusList: {},
  requestDemoModal: false,
  offerSalaryDetails: null,
  moveToAnotherStage: null,
  meetingPlaforms: [],
  shortlistedCandidatesApproval: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECEIVE_GET_PIPELINE_APPLICATIONS:
      return {
        ...state,
        list: { ...action.payload },
      };
    case types.SCHEDULE_INTERVIEW_RECIEVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.RECEIVE_GET_RESCHEDULE_DATA:
      return {
        ...state,
        rescheduleData: { ...action.payload },
      };
    case types.RECEIVE_RESCHEDULE_INTERVIEW:
      return {
        ...state,
        ...action.payload,
      };
    case types.RECEIVE_ADD_APPLICANTS:
      return {
        ...state,
        successAddedApplicant: action.payload,
      };
    case types.RECEIVE_GET_PIPELINE_STAGES:
      return {
        ...state,
        pipelineStages: [...action.payload],
      };
    case types.SAVE_CURRENT_STAGE_TAB_INDEX:
      return {
        ...state,
        currentStageIndex: action.payload,
      };
    case types.RECEIVE_PREOFFER_DATA:
      return {
        ...state,
        preofferData: action.payload,
      };
    case types.RECEIVE_GET_APPLICANT_RATE:
      return {
        ...state,
        rate: action.payload,
      };
    case types.DISABLE_ADD_APPLICANTS_RECEIVE:
      return {
        ...state,
        disableAddApplicants: action.payload,
      };
    case types.DISABLE_ENGAGEMENT_RECEIVE:
      return {
        ...state,
        disableEngagement: action.payload,
      };
    case types.RECEIVE_ADD_APPLICANT_MORE_INFO:
      return {
        ...state,
        successRequestMoreInfo: action.payload,
      };
    case types.GET_SEND_OFFER_TEMPLATE_RECEIVE:
      return {
        ...state,
        offerTemplate: action.payload,
      };
    case types.GET_OFFER_APPROVALS_SETUP_RECEIVE:
      return {
        ...state,
        isOfferHasApprovals: action.payload,
      };
    case types.GET_OFFER_APPROVALS_STATUS_LIST_RECEIVE:
      return {
        ...state,
        approvalStatusList: action.payload,
      };
    case types.GET_CONTRACT_APPROVALS_STATUS_LIST_RECEIVE:
      return {
        ...state,
        approvalStatusList: action.payload,
      };
    case types.GET_CONTRACT_APPROVALS_SETUP_RECEIVE:
      return {
        ...state,
        isContractHasApprovals: action.payload,
      };
    case types.GET_SEND_CONTRACT_TEMPLATE_RECEIVE:
      return {
        ...state,
        contractTemplate: action.payload,
      };
    case types.RECEIVE_SHORTLISTED_CANDIDATES_APPROVAL:
      return {
        ...state,
        shortlistedCandidatesApproval: action.payload,
      };
    case types.REQUEST_DEMO_Modal:
      return {
        ...state,
        requestDemoModal: action.payload,
      };
    case types.RECIEVE_OFFER_SALARY_DETAILS:
      return {
        ...state,
        offerSalaryDetails: action.payload,
      };
    case types.RECEIVE_MOVE_APPLICANT_ANOTHER_STAGE:
      return {
        ...state,
        moveToAnotherStage: action.payload,
      };
    case types.GET_CANDIDATE_PERMISSIONS_RECEIVE:
      return {
        ...state,
        candidatePermissions: action.payload,
      };
    case types.CLEAR_CANDIDATE_PERMISSIONS:
      return {
        ...state,
        candidatePermissions: [],
      };
    case types.GET_JOB_DETAILS_PERMISSIONS_RECEIVE:
      return {
        ...state,
        jobDetailsPermissions: action.payload,
      };
    case types.CLEAR_JOB_DETAILS_PERMISSIONS:
      return {
        ...state,
        jobDetailsPermissions: [],
      };
    case types.RECEIVE_MEETING_PLATFORMS:
      return {
        ...state,
        meetingPlaforms: action.payload,
      };
    case types.RECEIVE_HIRING_MANGER_APPROVAL:
      return {
        ...state,
        ...action.payload,
      };
    case types.UPLOAD_AND_VALIDATE_FILE_RECEIVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.UPDATE_UPLOAD_AND_VALIDATE_FILE_RECEIVE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
